<template>
  <div class="home">
    <div class="home-bg" />
    <div
      class="home-content"
      v-loading="loginLoading"
      element-loading-text="登录中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.57)"
    >
      <div class="password-input-container">
        <el-input
          v-model="password"
          class="password-input"
          type="password"
          clearable
          ref="password-input"
          placeholder="输入密码以登录"
          @keydown.enter.prevent.native="handleLogin"
        />
      </div>
      <div class="btn-container">
        <div class="password-btn" @click="handleLogin">登 录</div>
        <div
          class="password-btn"
          v-if="registerBtnShow"
          @click="passwordDialogVisible = true"
        >
          注 册
        </div>
      </div>
    </div>

    <div class="home-copyright">
      Ledger | Copyright © 2024 XuJingxin | Designed by XuJingxin & Deployed by
      <div class="copyright-link" @click="handleOpenLink">Ryan</div>
      .
    </div>

    <div class="back-to-home" @click="goHome">
      <div class="back-icon-container">
        <i class="el-icon-back back-icon"></i>
      </div>
      <div class="back-icon-container">
        <i class="el-icon-s-home home-icon"></i>
      </div>
    </div>

    <SetPasswordDialog
      :dialogVisible.sync="passwordDialogVisible"
      @home-check="checkHasPassword"
    />
  </div>
</template>

<script>
import SetPasswordDialog from "@/components/SetPasswordDialog/index.vue";
import Api from "@/api";
import * as Rsa from "@/utils/crypto.js";
import Cookies from "js-cookie";

export default {
  name: "Home",
  components: { SetPasswordDialog },
  data() {
    return {
      password: "",
      passwordDialogVisible: false,
      registerBtnShow: false,

      loginLoading: false, // 登录对话框转圈
    };
  },
  mounted() {
    this.checkHasPassword();
    setTimeout(() => {
      this.$refs["password-input"]
        ? this.$refs["password-input"].focus()
        : null;
    }, 1000);
  },
  methods: {
    async handleLogin() {
      if (this.loginLoading) {
        return;
      }
      if (this.registerBtnShow) {
        return this.$message.warning("请先注册，设定密码");
      }
      if (!this.password) {
        return this.$message.warning("请输入密码");
      }
      this.loginLoading = true;
      const res1 = await Api.AuthApi.getPublicKey("login").finally(() => {
        this.loginLoading = false;
      });
      if (res1 && res1.code === 0) {
        const publicKey = res1.data.publicKey || "";
        if (publicKey) {
          const cipherPassword = Rsa.rsaEncrypt(this.password, publicKey);
          this.loginLoading = true;
          const res2 = await Api.AuthApi.login({
            password: cipherPassword,
          }).finally(() => {
            this.loginLoading = false;
          });
          if (res2 && res2.code === 0) {
            if (res2.data.access_token) {
              const jwtPayloadBase64 = res2.data.access_token.split(".")[1];
              const jwtPayloadStr = atob(jwtPayloadBase64);
              const jwtPayload = JSON.parse(jwtPayloadStr);
              const nowTime = new Date().getTime(); // getTime() 是 精确到毫秒的时间戳
              const millisecond = jwtPayload.exp * 1000 - nowTime; // 返回的值和现在的时间的差别 毫秒
              const expires = new Date(new Date() * 1 + millisecond); // 生成一个新的时间
              Cookies.set("Authorization", res2.data.access_token, {
                expires,
              });
              this.$router.push("bill");
            }
          } else {
            this.$message.error("登录失败");
          }
        }
      } else {
        this.$message.error("获取公钥失败");
      }
    },
    /**
     * 检查是否已有存储密码
     */
    async checkHasPassword() {
      const res = await Api.AuthApi.homeCheck();
      if (res && res.code === 0) {
        console.log("是否已储存密码", res.data);
        if (res.data === false) {
          this.registerBtnShow = true;
        } else {
          this.registerBtnShow = false;
        }
      } else {
        this.$message.error("系统环境检查失败");
      }
    },
    handleOpenLink() {
      window.open("https://civiccccc.ltd");
    },
    goHome() {
      window.location.href = "https://www.xujingxin.fun";
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: relative;
  .home-bg {
    background-image: url("./../../assets/image/home.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: fixed;
    background-size: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
  .home-content {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    z-index: 100;
    position: absolute;
    width: 400px;
    height: 230px;
    left: 50%;
    top: 50%;
    transform: translate(-200px, -150px);
    border-radius: 40px;
    box-shadow: rgba(93, 74, 37, 0.695) 0px 0px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    .password-input-container {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      ::v-deep .password-input {
        margin: 0 auto;
        width: 270px;
        .el-input__inner {
          border-radius: 20px;
          background-color: rgba(83, 59, 34, 0.4);
          border-width: 2px;
          font-size: 40px;
          height: 40px;
          line-height: 40px;
          color: white;
        }
        .el-input__inner::placeholder {
          margin-left: 20px;
          font-size: 16px;
          line-height: 20px;
          height: 40px;
          position: relative;
          bottom: 9px;
        }
      }
    }
    .btn-container {
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .password-btn {
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        user-select: none;
        cursor: pointer;
        height: 40px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 0);

        transition: all 0.15s;
      }
      .password-btn:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .home-copyright {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(6px);
    z-index: 100;
    position: absolute;
    height: 24px;
    font-size: 12px;
    color: #fff;
    left: 10px;
    bottom: 10px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    padding: 0px 14px;
    user-select: none;
    .copyright-link {
      transition: all 0.2s;
      cursor: pointer;
      color: white;
      margin-left: 4px;
      line-height: 18px;
      height: 18px;
      border-radius: 4px;
    }
    .copyright-link:hover {
      background-color: rgb(161, 0, 0);
    }
  }

  .back-to-home {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 45px;
    top: 30px;
    border-radius: 50%;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: rgba(93, 74, 37, 0.695) 0px 0px 30px;

    transition: all 0.25s;
    cursor: pointer;
    user-select: none;

    position: relative;
    .back-icon-container {
      position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .back-icon {
        color: white;
        font-size: 40px;
        transition: all 0.25s;
        opacity: 1;
      }
      .home-icon {
        color: white;
        font-size: 38px;
        transition: all 0.25s;
        opacity: 0;
        transform: rotate(-90deg);
      }
    }
  }
  .back-to-home:hover {
    background-color: rgba(255, 255, 255, 0.3);
    .back-icon-container {
      .back-icon {
        transform: rotate(90deg);
        opacity: 0;
      }
      .home-icon {
        transform: rotate(0deg);
        opacity: 1;
      }
    }
  }
}
</style>
