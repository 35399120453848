<template>
  <el-dialog
    title="首次使用，设定密码"
    :visible.sync="dialogVisible"
    width="400px"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    top="30vh"
  >
    <div class="register-dialog-body">
      <el-form
        ref="register-form"
        :model="pwdForm"
        label-width="80px"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item label="设定密码" prop="password">
          <el-input
            v-model="pwdForm.password"
            type="password"
            show-password
            clearable
            :disabled="registerLoading"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="save" :loading="registerLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import * as Rsa from "@/utils/crypto.js";

export default {
  name: "SetPasswordDialog",
  data() {
    return {
      pwdForm: {
        password: "",
      },
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      registerLoading: false,
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleBeforeClose() {
      this.handleClose();
    },
    handleClose() {
      this.$refs["register-form"] && this.$refs["register-form"].resetFields();
      this.$emit("update:dialogVisible", false);
    },
    save() {
      this.registerLoading = true;
      this.$refs["register-form"] &&
        this.$refs["register-form"].validate(async (valid) => {
          if (!valid) {
            this.registerLoading = false;
            return;
          }
          const res = await Api.AuthApi.getPublicKey("register").finally(() => {
            this.registerLoading = false;
          });
          if (res && res.code === 0) {
            const publicKey = res.data.publicKey || "";
            // 获取到公钥了
            if (publicKey) {
              /** 加密后的密码 */
              const cipherPassword = Rsa.rsaEncrypt(
                this.pwdForm.password,
                publicKey
              );
              this.registerLoading = true;
              const res2 = await Api.AuthApi.savePassword({
                password: cipherPassword,
              }).finally(() => {
                this.registerLoading = false;
              });
              if (res2 && res2.code === 0) {
                this.$message.success("保存成功");
                this.$emit("home-check");
                this.handleClose();
              } else {
                this.$message.error("密码保存失败");
              }
            } else {
              this.$message.error("获取的公钥有问题");
            }
          } else {
            this.$message.error("无法获取公钥");
          }
        });
    },
  },
};
</script>

<style></style>
