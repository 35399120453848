import * as crypto from 'crypto';

/**
 * 生成Rsa密钥对
 * @returns 密钥对
 */
export function generateKeyPair() {
  const {
    publicKey,
    privateKey
  } = crypto.generateKeyPairSync('rsa', {
    modulusLength: 2048, // 模数长度，一般选择 2048 或以上
    publicKeyEncoding: {
      type: 'spki', // 公钥编码格式
      format: 'pem', // 公钥输出格式
    },
    privateKeyEncoding: {
      type: 'pkcs8', // 私钥编码格式
      format: 'pem', // 私钥输出格式
      // cipher: 'aes-256-cbc', // 加密算法，可选
      // passphrase: 'keykey', // 加密密码，可选 process.env.PASSWORD_RSA_KEY
    },
  });

  return {
    publicKey,
    privateKey,
  };
}

/**
 * 用 RSA 算法加密内容
 * @param {string} text 要加密的内容
 * @param {string} publicKey 加密用的公钥
 * @returns
 */
export function rsaEncrypt(text, publicKey) {
  // const publicKey = fs.readFileSync(path.resolve(__dirname, pathToPublicKey), 'utf8');
  const textBuffer = Buffer.from(text, 'utf8');
  return crypto
    .publicEncrypt({
      key: publicKey
    }, textBuffer)
    .toString('base64');
}

/**
 * 用 RSA 算法解密内容
 * @param {string} cipherText 要解密的文字
 * @param {*} privateKey 解密用的私钥
 * @returns
 */
export async function rsaDecrypt(cipherText, privateKey) {
  // const privateKey = fs.readFileSync(path.resolve(__dirname, pathToPrivateKey), 'utf8');
  const buffer = Buffer.from(cipherText, 'base64');
  const plain = crypto.privateDecrypt({
      key: privateKey,
      // cipher: 'aes-256-cbc', // 加密算法，可选
      // passphrase: 'keykey', // 加密密码，可选 process.env.PASSWORD_RSA_KEY
    },
    buffer);
  return plain.toString("utf8");
}
